import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    console.log("hello, stimulus is ready!", this.element)
  }
  // greet() {
  //   alert("hi!")
  //   console.log("Hello, Stimulus!", this.element)
  // }
}
